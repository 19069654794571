import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FeatureModule } from '@angular-common/feature.module';

import { TwebAuthInputComponent } from './form-controls/tweb-auth-input.component';
import { TwebInputNumericComponent } from './form-controls/tweb-input-numeric.component';
import { TwebInputComponent } from './form-controls/tweb-input.component';
import { TwebPasswordComponent } from './form-controls/tweb-password.component';
import { TwebPhoneInputComponent } from './form-controls/tweb-phone-input.component';
import { TwebSelectComponent } from './form-controls/tweb-select.component';
import { TwebTextAreaComponent } from './form-controls/tweb-textarea.component';
import { HeaderWrapperComponent } from './components/header-wrapper.component';
import { MessagingComponent } from './components/messaging.component';
import { PhotoUploaderComponent } from './components/photo-uploader/photo-uploader.component';
import { DonorPortalSharedModule } from './shared.module';
import { FileUploadModule } from 'ng2-file-upload';

/**
 * Feature Module contains all imports and exports that are specific to Features.  This makes
 * importing the standard grouping of mt-ng2 and angular modules easy and also helps with
 * bundling by having a consistent feature module grouping.
 *
 * Would only be imported into Features such as "CustomerModule" and "UserModule" and would
 * not be imported into root modules such as "AppModule" or "AdminPortalModule", nor other
 * base modules such as "AdminPortalSharedModule".
 *
 * Tip: If the import/export should be included in all modules, including the root AppModule at
 * startup, than consider putting it into "AdminPortalSharedModule" instead.
 */
@NgModule({
    declarations: [
        TwebAuthInputComponent,
        TwebPasswordComponent,
        TwebInputComponent,
        TwebSelectComponent,
        TwebInputNumericComponent,
        TwebTextAreaComponent,
        TwebPhoneInputComponent,
        HeaderWrapperComponent,
        MessagingComponent,
        PhotoUploaderComponent,
    ],
    entryComponents: [
        TwebAuthInputComponent,
        TwebPasswordComponent,
        TwebInputComponent,
        TwebSelectComponent,
        TwebInputNumericComponent,
        TwebTextAreaComponent,
        TwebPhoneInputComponent,
    ],
    exports: [
        HeaderWrapperComponent,
        MessagingComponent,
        FeatureModule,
        FileUploadModule,
        PhotoUploaderComponent,
    ],
    imports: [
        DonorPortalSharedModule,
        FileUploadModule,
        CommonModule,
        FeatureModule,
    ],
})
export class DonorPortalFeatureModule {}
