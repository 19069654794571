import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthService } from '@mt-ng2/auth-module';
import { AppStates, AppStateService } from '@common/services/app-state.service';

import breakpoints from './lib/breakpoints.lib';
import { VerifyEmailService } from './donor-portal/verify-email/verify-email.service';
import { RegistrationService } from './donor-portal/login/registration/registration.service';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from "@angular/common";

@Component({
    selector: 'app-root',
    template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'donor-portal';
    isAuthenticated: boolean;
    isMobile = false;
    mobileNavExpanded = false;
    appState: AppStates = AppStates.notLoggedIn;
    appStates = AppStates;
    subscriptions: Subscription = new Subscription();
    donorId: number;

    constructor(
        private authService: AuthService,
        private appStateService: AppStateService,
        private verifyEmailService: VerifyEmailService,
        private regService: RegistrationService,
    ) {}

    ngOnInit(): void {
        this.checkIfMobile();
        this.verifyEmailService.verifyEmail().subscribe(() => {
            this.subscriptions.add(
                this.appStateService.appState$.subscribe((state) => {
                    this.appState = state;
                }),
            );
            this.subscriptions.add(
                this.authService.isAuthenticated().subscribe((isAuthed) => {
                    this.isAuthenticated = isAuthed;
                    if (this.isAuthenticated) {
                        const user = this.authService.currentUser.value;
                        this.regService.refeshUserDetails(user.AuthId).subscribe(() => {
                            this.appStateService.resolveAppState();
                        });
                    }
                }),
            );
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    // Determine if mobile
    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.checkIfMobile();
    }

    checkIfMobile(): void {
        this.isMobile = window.innerWidth < breakpoints.large;
    }
}
