import { Component} from '@angular/core';
import { AppStates, AppStateService } from '@common/services/app-state.service';
import { Subscription } from 'rxjs';

@Component({
    selector: 'messaging',
    template: `
        <logged-in-header>
        <div class="main-layout">
            <div class="main-layout__wrapper">
                <div class="center-horizontal-vertical text-center">
                    <h2 *ngIf="title">{{title}}</h2>
                    <p *ngIf="message">
                        {{message}}
                        <span *ngIf="showContactInfo">
                        <br /><br />
                        The World Egg and Sperm Bank<br />
                        Phone: 602-678-1906<br />
                        Email: spermdonors@twesb.com<br />
                        </span>
                    </p>
                </div>
            </div>
        </div>
        </logged-in-header>
    `,
})

export class MessagingComponent {

    subs = new Subscription();

    appState: AppStates;
    message: string;
    title = 'Application Successfully Submitted';
    showContactInfo: boolean;

    constructor(private appStateService: AppStateService) { }

    ngOnInit(): void {
        this.subs.add(
            this.appStateService.appState$.subscribe((state) => {
                this.appState = state;
            }),
        );
        this.setMessage();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    setMessage(): void {
        switch (this.appState) {
            case AppStates.denied:
                this.message = `
                Thank you for your interest in becoming a sperm donor.
                Unfortunately, based on your responses thus far in the application, you do not qualify to be a sperm donor at this time.
                If you have any questions or concerns, please feel free to reach out to us.
                    `;
                this.showContactInfo = true;
                break;
            case AppStates.medicalReview:
                this.message = `
                    Congratulations, thank you for completing your donor application, our team is reviewing your application information and will reach out about next steps.`;
                break;
            case AppStates.testSampleScheduled:
                this.message = `Thank you for scheduling your Test Sample appointment. We look foward to meeting you`;
                this.title = `Appointment Successfully Scheduled`;
                break;
            case AppStates.interviewScheduled:
                this.message = `Thank you for scheduling your interview appointment. We look foward to meeting you`;
                this.title = `Appointment Successfully Scheduled`;
            default:
                // this doesn't do anything but redirect to 404;
                this.appStateService.setStateToError();
                break;
        }
    }
}
