<login-wrapper [backNavRouterLink]="'/login'" [backNavText]="'Login'" [loading]="loading">
    <div *ngIf="registering">
        <h2 class="auth-layout__h2">Please create a login to continue.</h2>
        <app-dynamic-form
            *ngIf="config.formObject && config.formObject.length"
            [config]="config.formObject"
            ([isEditing])="(isEditing)"
            (submitted)="formSubmitted($event)"
        >
            <input #honeypot type="checkbox" name="opt_in_to_emails" value="1" style="display: none !important" tabindex="-1" autocomplete="off" />
            <br />
            <div style="margin-top: 24px; margin-bottom: 32px">
                <p>
                    By creating an account, you have read and agree to our
                    <a
                        href="https://www.theworldeggandspermbank.com/wp-content/uploads/2018/05/terms-of-use.pdf"
                        rel="noopener noreferrer"
                        target="_blank"
                        >terms and conditions</a
                    >.
                </p>
            </div>
            <button
                type="submit"
                mt-doubleClickDisabled
                [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                Class="margin-bottom__xl button button--primary"
            >
                Register
            </button>
        </app-dynamic-form>
    </div>
    <div *ngIf="registerSuccess">
        <h2 class="auth-layout__h2">Thank you for registering!</h2>
        <p>
            You will receive a welcome email and an email verification shortly. Please add
            <strong class="brandBlue">spermdonors@twesb.com</strong> to your email contacts to ensure this email and future communications do not go
            to spam. If you do not receive the email within the next few minutes please check your spam folder.
        </p>
        <br />
        <p>Please take a moment to verify your email to continue. You can close this window, verification will log you in</p>
    </div>
</login-wrapper>
