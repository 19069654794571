import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { NavService } from '@mt-ng2/nav-module';
import { AuthService } from '@mt-ng2/auth-module';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { PageTitlesService } from '@mt-ng2/page-titles';
import { LoginConfig } from '@mt-ng2/login-module';
import { LoginConfigOverride } from '@common/configs/login.config';
import { AppStateService } from '@common/services/app-state.service';

@Component({
    selector: 'app-donor-portal-forgot-password',
    template: `
        <login-wrapper [backNavRouterLink]="'/login'" [backNavText]="'Login'" [loading]="loading">
            <div class="login-box">
                <img class="logo-img" src="{{ logoFull }}" alt="Logo" />
                <div class="login-box-body">
                    <p class="login-box-msg">{{ config.messageOverrides.forgotPasswordLabel }}</p>
                    <form [formGroup]="forgotPasswordForm" (submit)="onLogin()">
                        <div class="form-group has-feedback" [class.has-error]="emailHasError()">
                            <input type="text" autofocus class="form-control" placeholder="example@domain.com" formControlName="email" />
                            <span class="fa fa-user form-control-feedback"></span>
                            <div *ngIf="showEmailRequiredError()" class="small errortext" [style.position]="'block'">Email is required</div>
                            <div *ngIf="showEmailInvalidError()" class="small errortext" [style.position]="'block'">
                                Invalid email address. Valid e-mail can contain only letters, numbers and '@'
                            </div>
                        </div>
                        <div class="padded block-parent">
                            <button type="button" routerLink="/login" class="btn btn-default btn-flat inline-block block-left">Back</button>
                            <button [disabled]="!forgotPasswordForm.valid" type="submit" class="btn btn-primary btn-flat inline-block block-right">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </login-wrapper>
    `,
})
export class DonorForgotPasswordComponent implements OnInit {
    forgotPasswordForm: FormGroup;
    logoFull = `../../../assets/logo-full.png`;
    loading: boolean;
    public config: LoginConfig;

    constructor(
        private fb: FormBuilder,
        private navService: NavService,
        private authService: AuthService,
        private notificationsService: NotificationsService,
        private pageTitlesService: PageTitlesService,
        private appStateService: AppStateService,
    ) {}

    ngOnInit(): void {
        if (this.authService.appReady && !this.authService.appReady.getValue()) {
            this.authService.appReady.next(true);
        }
        this.appStateService.setStateToForgotPassword();
        this.config = new LoginConfig(LoginConfigOverride);
        this.navService.setShowNav(false);
        this.createForm();
        this.setPageTitle();
    }

    setPageTitle(): void {
        this.pageTitlesService.setPageTitle('Forgot Password');
    }

    createForm(): void {
        if (!this.forgotPasswordForm) {
            this.forgotPasswordForm = this.fb.group({});
        }
        this.forgotPasswordForm.addControl('email', new FormControl('', [Validators.required, Validators.email]));
    }

    emailHasError(): boolean {
        const control = this.forgotPasswordForm.get('email');
        return control.errors && (control.touched || control.errors.maxlength);
    }

    showEmailRequiredError(): boolean {
        const control = this.forgotPasswordForm.get('email');
        return control.touched && control.hasError('required');
    }

    showEmailInvalidError(): boolean {
        const control = this.forgotPasswordForm.get('email');
        return control.touched && control.hasError('email');
    }

    onLogin(): void {
        if (this.forgotPasswordForm.valid) {
            const values = this.forgotPasswordForm.value;
            this.loading = true;
            this.authService.forgot(values.email).subscribe(
                (success) => {
                    this.notificationsService.success(this.config.messageOverrides.forgotPasswordSuccess);
                    this.loading = false;
                },
                (errorResponse) => {
                    if (errorResponse.status === 400) {
                        if (errorResponse.error && errorResponse.error.Message) {
                            this.notificationsService.error(errorResponse.error.Message);
                        } else {
                            this.notificationsService.error(this.config.messageOverrides.forgotPasswordFailure);
                        }
                    }
                    this.loading = false;
                },
            );
        } else {
            markAllFormFieldsAsTouched(this.forgotPasswordForm);
        }
    }
}
