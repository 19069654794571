import { NgModule } from '@angular/core';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { IEntityListModuleConfig } from '@mt-ng2/entity-list-module-config';
import {SharedModule } from '@angular-common/shared.module';

export const entityListModuleConfig: IEntityListModuleConfig = {
    itemsPerPage: 10,
};

/**
 * Shared Module contains all imports and exports that are commonly used in all other modules.
 * Since this is imported into the AppModule, only things that are needed on startup should be
 * included.
 *
 * Is imported into nearly all other modules, including the root module "AppModule".  forRoot
 * is only called from AppModule.
 *
 * Tip: If the import/export should only be included in feature modules, than consider putting
 * it into "AdminPortalFeatureModule" instead.  If the import/export is only needed at the AppModule level
 * and rarely/never needs to be imported into any other modules, than consider putting it into
 * "AppModule" instead.
 */
@NgModule({
    exports: [
        SharedModule,
        NgxSkeletonLoaderModule,
    ],
    imports: [
        SharedModule,
        NgxSkeletonLoaderModule,
    ],
})
export class DonorPortalSharedModule {
    static forRoot(): any {
        return {
            ngModule: DonorPortalSharedModule,
        };
    }
}
