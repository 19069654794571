import { Component, ChangeDetectorRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { CustomFormControlComponentBase } from '@mt-ng2/dynamic-form';
import { formatPhone } from '@mt-ng2/format-functions';

@Component({
    selector: 'tweb-phone-input-dynamic-field-component',
    template: `
        <dynamic-form-input-wrapper [parentControl]="thisControl" [formGroup]="getGroup()">
            <div class="input-scaffold">
                <label class="input-label">
                    <span class="input-scaffold__label">Phone Number <span *ngIf="required" style="color:red"> *</span></span>
                    <!-- input element -->
                    <input type="tel" class="hidden" [formControlName]="config.name" [value]="config.value" />
                    <input
                        #inputElement
                        type="tel"
                        (focus)="onFocus()"
                        (blur)="onBlur()"
                        [attr.placeholder]="config.placeholder"
                        phoneMask
                        (keydown.enter)="setPhoneValue()"
                    />
                    <!-- maxlength elements -->
                    <span
                        *ngIf="!config.labelLeft && hasMaxLength()"
                        [style.visibility]="isFocused && hasMaxLength() ? null : 'hidden'"
                        class="small pull-right"
                        [class.errortext]="hasError('maxlength')"
                        [class.inside-box-maxlength]="config.insideBoxValidation"
                        >{{ getMaxLengthText() }}</span
                    >
                    <span
                        *ngIf="config.labelLeft"
                        [style.visibility]="isFocused && hasMaxLength() ? null : 'hidden'"
                        class="small pull-right"
                        [class.errortext]="hasError('maxlength')"
                        [class.inside-box-maxlength]="config.insideBoxValidation"
                        >{{ getMaxLengthText() }}</span
                    >
                    <!-- validation elements -->
                    <div *ngIf="config.validators">
                        <div
                            *ngIf="!showRequired() && hasError('required')"
                            class="small errortext"
                            [class.inside-box-validation]="config.insideBoxValidation"
                        >
                            Phone number is required
                        </div>
                        <div *ngIf="hasError('minlength')" class="small errortext" [class.inside-box-validation]="config.insideBoxValidation">
                            Phone number must be at least {{ config.validators.minlength }} characters
                        </div>
                    </div>
                </label>
            </div>
        </dynamic-form-input-wrapper>
    `,
})
export class TwebPhoneInputComponent extends CustomFormControlComponentBase {
    required = true;
    constructor(changeDetectorRef: ChangeDetectorRef) {
        super(changeDetectorRef);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.config.insideBoxValidation = true;
        this.required = this.config.validators.required || this.config.validation.includes(Validators.required);
        this.subscribeToStatusChanges();
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        this.formatNativePhoneElement();
    }

    subscribeToStatusChanges(): void {
        this.subscriptions.add(
            this.getControl().statusChanges.subscribe((status) => {
                this.inputElement.nativeElement.disabled = status === 'DISABLED';
            }),
        );
    }

    formatNativePhoneElement(): void {
        this.inputElement.nativeElement.value = formatPhone(<string>this.config.value);
    }

    onBlur(): void {
        if (this.getControl().enabled) {
            this.setPhoneValue();
            this._isFocused = false;
            this.blur.emit(true);
        }
    }

    setPhoneValue(): void {
        const control = this.getControl();
        const phoneControl = this.inputElement;
        let value = phoneControl.nativeElement.value;
        value = value.replace(/\D/g, '');
        if (control.value !== value) {
            control.setValue(value);
            control.markAsDirty();
        }
    }
}
