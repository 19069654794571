import { Component, HostListener, Input, OnInit } from '@angular/core';
import { environment } from '@environment';
import { AppointmentTypes } from '@model/enums/appointment-types.enum';
import { IDonor } from '@model/interfaces/donor';
import { Subscription } from 'rxjs';
import { AppStates, AppStateService } from '../common/services/app-state.service';
import { DonorService } from '../common/services/donor.service';
import {DonorStatuses} from '@model/enums/donor-statuses.enum';

declare var Calendly;

const AppointmentButtonTitleMap = new Map<AppointmentTypes, string>([
    [AppointmentTypes.TestSample, 'Schedule Test Sample Appointment'],
    [AppointmentTypes.InterviewZoom, 'Schedule Zoom Interview'],
    [AppointmentTypes.InterviewInPerson, 'Schedule In Person Interview'],
]);

const AppointmentUrlMap = new Map<AppointmentTypes, string>([
    [AppointmentTypes.TestSample, environment.calendlyTestSampleAppointmentUrl],
    [AppointmentTypes.InterviewZoom, environment.calendlyInterviewAppointmentZoomUrl],
    [AppointmentTypes.InterviewInPerson, environment.calendlyInterviewAppointmentInPersonUrl],
]);

@Component({
    selector: 'appointment-scheduling',
    template: `
        <logged-in-header>
        <div class="main-layout">
            <div class="main-layout__wrapper">
                <div class="center-horizontal-vertical text-center">
            <div *ngIf="donor">
                <h2>Application Submitted Successfully</h2>
                <p>
                    {{ message }}
                </p>
                <br />
                <br />
                <button *ngIf="appointmentType" type="button" class="btn button--primary text-center" (click)="showAppointmentScheduler(appointmentType)">
                    {{ getButtonTitle(appointmentType) }}
                </button>
                <button *ngIf="secondAppointment" type="button" class="btn button--primary text-center" (click)="showAppointmentScheduler(secondAppointment)">
                    {{ getButtonTitle(secondAppointment) }}
                </button>
            </div>
            </div></div></div>
        </logged-in-header>
    `,
})
export class ScheduleCalendlyComponent implements OnInit {
    donor: IDonor;

    selectedAppointment: Date;

    appointmentType: AppointmentTypes;
    secondAppointment: AppointmentTypes;

    subs = new Subscription();

    titleMap = AppointmentButtonTitleMap;
    urlMap = AppointmentUrlMap;

    message: string;

    get appointmentTypeText(): string {
        return this.appointmentType === AppointmentTypes.TestSample ?
        'a test sample' :
        'an interview';
    }

    constructor(private donorService: DonorService, private appStateService: AppStateService) {}

    ngOnInit(): void {
        this.donorService.getLoggedInUserDetails().subscribe((donor) => (this.donor = donor));
        this.subs.add(this.appStateService.appState$.subscribe((state) => {
            if (state === AppStates.testSampleNotScheduled) {
                this.appointmentType = AppointmentTypes.TestSample;
                this.secondAppointment = null;
            } else if (state === AppStates.interviewNotScheduled) {
                this.appointmentType = AppointmentTypes.InterviewInPerson;
                this.secondAppointment = AppointmentTypes.InterviewZoom;
            }
            this.message = `Thank you for completing your donor application. In  order to continue please schedule ${this.appointmentTypeText} appointment below.`;
        }));
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    // Listen for calendly events
    @HostListener('window:message', ['$event'])
    handleEvent(event: MessageEvent): void {
        if (this.isCalendlyEvent(event)) {
            if (event.data.event === 'calendly.event_scheduled') {
                if (this.appointmentType === AppointmentTypes.TestSample) {
                    this.appStateService.setStateToTestSampleScheduled();
                    this.donorService.setDonorStatusId(this.donor.Id, DonorStatuses.TestSampleScheduled).subscribe();
                } else {
                    this.appStateService.setStateToInterviewScheduled();
                    this.donorService.setDonorStatusId(this.donor.Id, DonorStatuses.InterviewScheduled).subscribe();
                }
            }
        }
    }

    isCalendlyEvent(e): boolean {
        return e.data.event && e.data.event.indexOf('calendly') === 0;
    }

    getButtonTitle(type: AppointmentTypes): string {
        return AppointmentButtonTitleMap.get(type);
    }

    getAppointmentUrl(type: AppointmentTypes): string {
        return AppointmentUrlMap.get(type);
    }

    showAppointmentScheduler(type: AppointmentTypes): void {
        const url = this.getAppointmentUrl(type);
        Calendly.initPopupWidget({
            prefill: {
                customAnswers: {
                    a1: this.donor.Id.toString(),
                    a2: +`1${this.donor.DonorBasicInfo.ApplicantPhoneNumber}`,
                },
                email: this.donor.DonorUser.Email,
                name: `${this.donor.DonorUser.FirstName} ${this.donor.DonorUser.LastName}`,
            },
            url: url,
        });
    }

}
