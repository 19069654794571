<div class="auth-layout">
    <div class="auth-layout__left">
        <img src="../assets/logo-white.png" height="79" width="150" alt="tweb logo" />
    </div>
    <div class="auth-layout__right" style="margin-top: 130px">
        <div class="auth-layout__right__children">
            <div class="auth-layout__form-container">
                <h2 class="auth-layout__h2">Welcome to The World Egg and Sperm Bank</h2>
                <p>It looks like your email is not yet verified. Please take a moment to verify your email.</p>
                <br />
                <button
                    type="button"
                    class="btn btn-flat button--primary-alt center"
                    (click)="SendVerificationEmail()"
                    mt-doubleClickDisabled
                    [(doubleClickIsDisabled)]="doubleClickIsDisabled"
                >
                    Resend Verification Email
                </button>
                <br />
                <p>
                    Please add <strong class="brandBlue">spermdonors@twesb.com</strong> to your email contacts, if you have not already, to ensure
                    this email does not go to spam. If you do not receive the email within the next few minutes please check your spam folder.
                </p>
                <br />
                <p class="small">Verification link will expire after 30 minutes</p>
            </div>
        </div>
    </div>
</div>
