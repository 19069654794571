import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, ILoggedIn } from '@mt-ng2/auth-module';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { appPaths } from '../../../donor-portal/default-routes/app-paths.library';
import { DonorStatuses } from '@model/enums/donor-statuses.enum';
import { IDonorLoggedIn } from '@model/interfaces/custom/donor-logged-in-user';

export enum AppStates {
    applicationComplete,
    applyingPartOne,
    applyingPartTwo,
    denied,
    error,
    medicalReview,
    notLoggedIn,
    testSampleNotScheduled,
    testSampleScheduled,
    interviewNotScheduled,
    unverified,
    forgotUsername,
    forgotPassword,
    resetPassword,
    register,
    interviewScheduled,
}

export const AppStatePathMap = new Map<AppStates, string>([
    [AppStates.notLoggedIn, appPaths.login],
    [AppStates.unverified, appPaths.verifyUser],
    [AppStates.applyingPartOne, appPaths.applicationPartOne],
    [AppStates.denied, appPaths.message],
    [AppStates.medicalReview, appPaths.message],
    [AppStates.testSampleNotScheduled, appPaths.schedule],
    [AppStates.testSampleScheduled, appPaths.message],
    [AppStates.interviewScheduled, appPaths.message],
    [AppStates.applyingPartTwo, appPaths.applicationPartTwo],
    [AppStates.interviewNotScheduled, appPaths.schedule],
    [AppStates.applicationComplete, appPaths.account],
    [AppStates.forgotUsername, appPaths.forgotUsername],
    [AppStates.forgotPassword, appPaths.forgotPassword],
    [AppStates.resetPassword, appPaths.resetPassword],
    [AppStates.register, appPaths.register],
]);

@Injectable({
    providedIn: 'root',
})
export class AppStateService {
    appState = new BehaviorSubject<AppStates>(null);
    appState$ = <Observable<AppStates>>this.appState.pipe(
        tap((state) => {
            const path = AppStatePathMap.get(state);
            this._path = null;
            if (path) {
                this._path = path;
                this.router.navigate([path]);
            }
        }),
    );

    private _path: string;
    get CurrentPath(): string {
        return this._path;
    }

    constructor(private authService: AuthService, private router: Router) {}

    resolveAppState(): void {
        const user: IDonorLoggedIn = this.authService.currentUser.getValue();
        if (!user.CustomOptions.EmailVerified) {
            this.setStateToUnverified();
        } else if (user.CustomOptions.Denied) {
            this.setStateToDenied();
        } else if (user.CustomOptions.MedicalReview) {
            this.setStateToMedicalReview();
        } else if (!user.CustomOptions.ApplicationPartOneComplete && user.CustomOptions.DonorStatusId !== DonorStatuses.TestSampleSchedulePending) {
            this.setStateToApplyingPartOne();
        } else {
            this.resolveStateFromDonorStatus(user.CustomOptions.DonorStatusId);
        }
    }

    resolveStateFromDonorStatus(donorStatusId: number): void {
        if (donorStatusId === DonorStatuses.PendingMedicalReview) {
            this.setStateToMedicalReview();
        } else if (donorStatusId === DonorStatuses.TestSampleSchedulePending) {
            this.setStateToTestSampleNotScheduled();
        } else if (donorStatusId === DonorStatuses.TestSampleScheduled) {
            this.setStateToTestSampleScheduled();
        } else if (donorStatusId === DonorStatuses.PersonalHistory) {
            this.setStateToApplyingPartTwo();
        } else if (donorStatusId === DonorStatuses.InterviewSchedulePending) {
            this.setStateToInterviewNotScheduled();
        } else {
            this.setStateToApplicationComplete();
        }
    }

    setStateToUnverified(): void {
        this.appState.next(AppStates.unverified);
    }

    setStateToNotLoggedIn(): void {
        this.authService.logout();
        this.appState.next(AppStates.notLoggedIn);
    }

    setStateToApplyingPartOne(): void {
        this.appState.next(AppStates.applyingPartOne);
    }

    setStateToApplyingPartTwo(): void {
        this.appState.next(AppStates.applyingPartTwo);
    }

    setStateToError(): void {
        this.appState.next(AppStates.error);
    }

    setStateToDenied(): void {
        let user = this.authService.currentUser.getValue();
        user.CustomOptions.Denied = true;
        this.authService.updateCurrentUser(user);
        this.appState.next(AppStates.denied);
    }

    setStateToMedicalReview(): void {
        this.appState.next(AppStates.medicalReview);
    }

    setStateToTestSampleScheduled(): void {
        this.appState.next(AppStates.testSampleScheduled);
    }

    setStateToTestSampleNotScheduled(): void {
        this.appState.next(AppStates.testSampleNotScheduled);
    }

    setStateToInterviewScheduled(): void {
        this.appState.next(AppStates.interviewScheduled);
    }

    setStateToInterviewNotScheduled(): void {
        this.appState.next(AppStates.interviewNotScheduled);
    }

    setStateToApplicationComplete(): void {
        this.appState.next(AppStates.applicationComplete);
    }

    setStateToForgotUsername(): void {
        this.appState.next(AppStates.forgotUsername);
    }

    setStateToForgotPassword(): void {
        this.appState.next(AppStates.forgotPassword);
    }

    setStateToResetPassword(): void {
        this.appState.next(AppStates.resetPassword);
    }

    setStateToRegister(): void {
        this.appState.next(AppStates.register);
    }

    updateDonorStatus(donorStatusId: number): void {
        let user = this.authService.currentUser.getValue();
        user.CustomOptions.DonorStatusId = donorStatusId;
        this.authService.updateCurrentUser(user);
        this.resolveStateFromDonorStatus(donorStatusId);
    }
}
