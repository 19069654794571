import { NgModule } from '@angular/core';

import { DonorPortalSharedModule } from '@common/shared.module';

import { LoginOverrideComponent } from './login/login-override.component';
import { DonorResetPasswordComponent } from './donor-password/reset-password.component';
import { DonorForgotPasswordComponent } from './donor-password/forgot-password.component';
import { ForgotUsernameComponent } from './forgot-username/forgot-username.component';
import { RegistrationComponent } from './registration/registration.component';
import { DonorPortalFeatureModule } from '@common/feature.module';
import { DonorLoginWrapperComponent } from './donor-login-wrapper.component';

@NgModule({
    declarations: [LoginOverrideComponent, DonorForgotPasswordComponent, DonorResetPasswordComponent, ForgotUsernameComponent, RegistrationComponent, DonorLoginWrapperComponent],
    exports: [LoginOverrideComponent, DonorForgotPasswordComponent, DonorResetPasswordComponent, ForgotUsernameComponent, RegistrationComponent],
    imports: [DonorPortalSharedModule, DonorPortalFeatureModule],
})
export class DonorLoginModule {
    static forRoot(): any {
        return {
            ngModule: DonorLoginModule,
            providers: [],
        };
    }
}
