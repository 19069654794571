import { Component, Input } from '@angular/core';

@Component({
    selector: 'login-wrapper',
    template: `
        <div class="auth-layout">
            <div class="auth-layout__left">
                <img src="../assets/logo-white.png" height="79" width="150" alt="tweb logo" />
            </div>
            <div class="auth-layout__right">
                <div>
                    <a *ngIf="backNavHref" class="button--text button--has-icon" [href]="backNavHref" rel="noopener noreferrer">
                        <i class="fa fa-arrow-left"></i> Back to {{ backNavText }}
                    </a>
                    <a *ngIf="backNavRouterLink" class="button--text button--has-icon" [routerLink]="[backNavRouterLink]" rel="noopener noreferrer">
                        <i class="fa fa-arrow-left"></i> Back to {{ backNavText }}
                    </a>
                </div>
                <div class="auth-layout__right__children">
                    <div class="auth-layout__form-container">
                        <h2 class="auth-layout__h2 center" *ngIf="loading">Loading</h2>
                        <ngx-skeleton-loader
                            count="3"
                            appearance="circle"
                            animation="progress"
                            [theme]="{
                                height: '30px',
                                'background-color': '#d1a053',
                                'margin-left': '30px'
                            }"
                            *ngIf="loading"
                        ></ngx-skeleton-loader>
                        <ng-content *ngIf="!loading"></ng-content>
                    </div>
                </div>
                <div class="auth-layout__footer">
                    <div class="auth-layout__footer">
                        <a
                            href="https://www.theworldeggandspermbank.com/wp-content/uploads/2018/05/terms-of-use.pdf"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            Terms of Use
                        </a>
                        | <a href="https://www.theworldeggandspermbank.com/contact-us/" target="_blank">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    `,
})
export class DonorLoginWrapperComponent {
    @Input() backNavText: string;
    @Input() backNavHref: string;
    @Input() backNavRouterLink: string;
    @Input() loading: boolean;
}
