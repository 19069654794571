export const appPaths = {
    account: 'account',
    adminAccess: 'adminaccess',
    applicationPartOne: 'donor-application',
    applicationPartTwo: 'personal-history',
    forgotPassword: 'forgotpassword',
    forgotUsername: 'forgotusername',
    home: 'home',
    login: 'login',
    message: 'message',
    register: 'register',
    resetPassword: 'resetpassword',
    schedule: 'schedule',
    verifyEmail: 'verifyemail',
    verifyUser: 'verifyuser',
};
