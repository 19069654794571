import { Component, HostListener } from '@angular/core';
import { AppStateService } from '@common/services/app-state.service';
import breakpoints from 'lib/breakpoints.lib';

@Component({
    selector: 'logged-in-header',
    template: `
    <div class="main-layout">
        <div class="header">
            <div class="header__wrapper">
                <div class="header__branding">
                    <div>
                        <img src="./assets/logo-white.png" alt="tweb-logo" />
                    </div>
                    <a *ngIf="isMobile" class="hamburger" [ngClass]="{ active: mobileNavExpanded }" (click)="mobileNavExpanded = !mobileNavExpanded"
                        ><span></span
                    ></a>
                </div>
                <nav *ngIf="(isMobile && mobileNavExpanded) || !isMobile" [ngClass]="{ header__navigation: !isMobile, mobile__navigation: isMobile }">
                    <ul>
                        <li><a href="https://www.theworldeggandspermbank.com/" title="Back to Main Site">Return to Main Site</a></li>
                        <li><a (click)="logout(); mobileNavExpanded = false" [ngClass]="{ button: !isMobile }">Logout</a></li>
                    </ul>
                </nav>
            </div>
        </div>
        <ng-content></ng-content>
        <div class="footer">
            <span>Copyright &copy; 2022 The World and Sperm Egg Bank, All Rights Reserved.</span>
        </div>
    </div>
    `,
})
export class HeaderWrapperComponent {
    isMobile = false;
    mobileNavExpanded = false;

    constructor(private appStateService: AppStateService) { }

    ngOnInit(): void {
        this.checkIfMobile();
    }

    // Determine if mobile
    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.checkIfMobile();
    }

    checkIfMobile(): void {
        this.isMobile = window.innerWidth < breakpoints.large;
    }

    logout(): void {
        this.appStateService.setStateToNotLoggedIn();
    }
}
