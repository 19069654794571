import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { AuthService } from '@mt-ng2/auth-module';
import { filter, finalize } from 'rxjs/operators';
import { RegistrationService } from './registration.service';
import { AuthUserDynamicConfig } from './auth-user.dynamic-config';
import { DonorRegistrationDynamicConfig } from './donor-registration.dynamic-config';
import { AppStateService } from '../../common/services/app-state.service';
import { IAuthUser } from '@model/interfaces/auth-user';
import { IDonorUser } from '@model/interfaces/donor-user';
import { ICreateDonorPayload } from '@model/interfaces/custom/create-donor-payload';
import { Router, NavigationEnd } from '@angular/router';

declare let gtag: Function;
@Component({
    selector: 'app-register',
    styles: [
        `
            .fa-google {
                padding-right: 10px;
            }
        `,
    ],
    templateUrl: './registration.component.html',
})
export class RegistrationComponent implements OnInit {
    authUser: IAuthUser;
    isEditing = true;
    config: any = {};
    userForm: any;
    formFactory: DonorRegistrationDynamicConfig<IDonorUser>;
    doubleClickIsDisabled = false;
    user: IDonorUser;
    formReady = false;
    @ViewChild('honeypot') honeypot: ElementRef;
    pageLoadTime: Date;
    loading: boolean;

    registering = true;
    registerSuccess = false;

    constructor(
        private registrationService: RegistrationService,
        private notificationsService: NotificationsService,
        private authService: AuthService,
        private cdr: ChangeDetectorRef,
        protected appStateService: AppStateService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.appStateService.setStateToRegister();
        this.pageLoadTime = new Date();
        this.user = this.registrationService.getEmptyUser();
        this.config = { formObject: [], viewOnly: [] };
        this.setConfig();
    }

    getAdditionalConfigs(): AuthUserDynamicConfig<IAuthUser>[] {
        let configControls: string[] = ['Username', 'Password', 'ConfirmPassword'];
        const authUser = null;
        const config = new AuthUserDynamicConfig<IAuthUser>(authUser, null, configControls);
        return [config];
    }

    setConfig(): void {
        this.formFactory = new DonorRegistrationDynamicConfig<IDonorUser>(this.user);
        this.config = this.formFactory.getForCreate(this.getAdditionalConfigs());
        this.cdr.detectChanges();
    }

    formSubmitted(form): void {
        // Check if hidden form element is checked or if form is submitted in under 3 seconds to detect bot spam
        if (this.honeypot.nativeElement.checked || new Date().getTime() - this.pageLoadTime.getTime() < 3000) {
            return;
        }
        let passwordmatch = true;
        if (!this.authService.matchPassword(form)) {
            passwordmatch = false;
        }

        if (form.valid && passwordmatch) {
            this.formFactory.assignFormValues(this.user, form.value.DonorUser);
            this.user.MiddleInitial = form.value.DonorUser.MiddleInitial;
            const data: ICreateDonorPayload = {
                Password: form.value.AuthUser.Password,
                SendEmail: form.value.AuthUser.SendResetEmail || false,
                User: this.user,
                Username: form.value.AuthUser.Username,
            };
            // handle new user save
            this.loading = true;
            this.registrationService
                .registerDonor(data)
                .pipe(finalize(() => (this.doubleClickIsDisabled = false)))
                .subscribe((answer) => {
                    this.success();
                    this.authService.login(data.Username, data.Password, false).subscribe(
                        (loginResponse) => {
                            this.registering = false;
                            this.registerSuccess = true;
                            this.loading = false;
                            this.triggerNewUserActions(loginResponse.UserDetails.Id);
                        },
                        (errorResponse) => {
                            this.notificationsService.error('We were unable to log you in. Please try again later.');
                            this.loading = false;
                        },
                    );
                });
        } else {
            if (!passwordmatch) {
                this.error('Passwords do not match');
                this.enableDoubleClick();
            } else {
                markAllFormFieldsAsTouched(form);
                this.error();
                this.enableDoubleClick();
            }
        }
    }

    triggerNewUserActions(userId: number): void {
        this.registrationService.triggerNewUserActions(userId).subscribe(
            (successResponse) => {},
            (errorResponse) => {
                this.notificationsService.error('There was an error sending the verification email, please contact The World Egg and Sperm Bank.');
            },
        );
    }

    enableDoubleClick(): void {
        setTimeout(() => {
            this.doubleClickIsDisabled = false;
        });
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error(`Save failed.  Please check the form and try again.`);
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success('Registered Successfully');
    }

    setUpAnalytics(): void {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            gtag('config', 'GTM-KKJK3HT', {
                page_path: event.urlAfterRedirects,
            });
        });
    }
}
