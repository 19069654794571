import { IAuthUser } from '../interfaces/auth-user';
import { AuthUserDynamicControlsExtended } from './auth-user.form-controls';
import { IUserRole } from '../interfaces/user-role';
import { DynamicField } from '@mt-ng2/dynamic-form';
import { Validators } from '@angular/forms';

export class DonorAuthUserDynamicControlsPartial extends AuthUserDynamicControlsExtended {
    constructor(authUserPartial?: IAuthUser, userRoles?: IUserRole[], configControls?: string[]) {
        super(authUserPartial, userRoles, configControls);

        // 8 charcters, one number, one uppercase letter, one lowercase letter, and specialcharacter
        const passwordPattern = '^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$';
        const passwordRegexPattern = new RegExp(passwordPattern);

        (<DynamicField>this.Form.Password).validation = [Validators.required, Validators.pattern(passwordRegexPattern)];
        (<DynamicField>this.Form.Password).validators = {
            pattern: passwordRegexPattern,
            required: true,
        };
        (<DynamicField>this.Form.Password).failedPatternMessage =
            'Password must be at least 8 characters long and contain at least one number, one uppercase letter, one lowercase letter, and a special character.';

        (<DynamicField>this.Form.ConfirmPassword).validation = [Validators.required, Validators.pattern(passwordRegexPattern)];
        (<DynamicField>this.Form.ConfirmPassword).validators = {
            pattern: passwordRegexPattern,
            required: true,
        };
        (<DynamicField>this.Form.ConfirmPassword).failedPatternMessage =
            'Password must be at least 8 characters long and contain at least one number, one uppercase letter, one lowercase letter, and a special character.';
    }
}
