import { NgModule } from '@angular/core';
import { DonorPortalFeatureModule } from '@common/feature.module';
import { DonorPortalSharedModule } from '@common/shared.module';

import { ScheduleCalendlyComponent } from './schedule-calendly.component';

@NgModule({
    declarations: [ScheduleCalendlyComponent],
    exports: [],
    imports: [DonorPortalSharedModule, DonorPortalFeatureModule],
    providers: [ ],
})
export class ScheduleCalendlyModule { }
