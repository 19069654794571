import { Component, ChangeDetectorRef } from '@angular/core';
import { Validators } from '@angular/forms';
import { CustomFormControlComponentBase } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'tweb-textarea-dynamic-field-component',
    template: `
        <dynamic-form-input-wrapper [parentControl]="thisControl" [formGroup]="getGroup()">
            <label class="input-label">
                <span class="input-scaffold__label" *ngIf="config.name !== 'Comment'"
                    >{{ config.label }} <span *ngIf="required" style="color:red"> *</span></span
                >
                <textarea
                    [style.height]="'200px'"
                    [attr.type]="'textarea'"
                    [attr.placeholder]="config.placeholder || 'Please write in complete sentences...'"
                    (focus)="onFocus()"
                    (blur)="onBlur()"
                    [formControlName]="config.name"
                    [maxlength]="getMaxLength()"
                ></textarea>
            </label>
            <span
                *ngIf="!config.labelLeft && hasMaxLength()"
                [style.visibility]="isFocused && hasMaxLength() ? null : 'hidden'"
                class="small pull-right"
                [class.errortext]="hasError('maxlength')"
                [class.inside-box-maxlength]="config.insideBoxValidation"
                >{{ getMaxLengthText() }}</span
            >
            <span
                *ngIf="config.labelLeft"
                [style.visibility]="isFocused && hasMaxLength() ? null : 'hidden'"
                class="small pull-right"
                [class.errortext]="hasError('maxlength')"
                [class.inside-box-maxlength]="config.insideBoxValidation"
                >{{ getMaxLengthText() }}</span
            >
            <!-- validation elements -->
            <div *ngIf="config.validators">
                <div *ngIf="showRequired() && hasError('required')" class="small" [class.inside-box-validation]="config.insideBoxValidation">
                    {{ config.label }} is required
                </div>
                <div *ngIf="hasError('minlength')" class="small" [class.inside-box-validation]="config.insideBoxValidation">
                    {{ config.label }} must be at least {{ config.validators.minlength }} characters
                </div>
                <div
                    *ngIf="!hasError('required') && hasError('email')"
                    class="small errortext"
                    [class.inside-box-validation]="config.insideBoxValidation"
                >
                    Invalid email address. Valid e-mail can contain only letters, numbers and '@'
                </div>
            </div>
        </dynamic-form-input-wrapper>
    `,
})
export class TwebTextAreaComponent extends CustomFormControlComponentBase {
    required = true;

    constructor(changeDetectorRef: ChangeDetectorRef) {
        super(changeDetectorRef);
    }

    ngOnInit(): void {
        this.config.insideBoxValidation = true;
        this.required = this.config.validators.required || this.config.validation.includes(Validators.required);
    }
}
