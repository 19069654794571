import { NgModule } from '@angular/core';
import { NotFoundComponent } from '../default-routes/not-found/not-found.component';
import { RouterModule, Routes } from '@angular/router';
import { VerifyEmailComponent } from './verify-email.component';
import { appPaths } from '../default-routes/app-paths.library';
import { AuthGuard } from '@mt-ng2/auth-module';
import { RouteGuard } from 'donor-portal/default-routes/route-gaurd';

const verifyEmailRoute: Routes = [
    {
        canActivate: [AuthGuard, RouteGuard],
        children: [{ path: '**', component: NotFoundComponent }],
        component: VerifyEmailComponent,
        path: appPaths.verifyUser,
        pathMatch: 'full',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(verifyEmailRoute)],
    providers: [],
})
export class VerifyEmailRoutingModule {}
