import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseService } from '@mt-ng2/base-service';

import { IDonor } from '@model/interfaces/donor';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DonorService extends BaseService<IDonor> {
    constructor(public http: HttpClient) {
        super('/donors', http);
    }

    /**
     * @returns details needed to assess the completed status of an application
     */
    getLoggedInUserDetails(): Observable<IDonor> {
        return this.http.get<IDonor>('/donors/details');
    }

    /**
     * @returns only data needed for the account page
     */
    getLoggedInUserAccountDetails(): Observable<IDonor> {
        return this.http.get<IDonor>('/donors/account');
    }
	
    ///
    /// Calls the API to update the donor status in the database
    ///
    setDonorStatusId(donorId: number, donorStatusId: number): Observable<number> {
        return this.http.put<number>(`/donors/${donorId}/setStatus`, donorStatusId);
    }
}
