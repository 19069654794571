import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { AuthService } from '@mt-ng2/auth-module';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { Observable, of } from 'rxjs';
import { AppStateService } from '../common/services/app-state.service';
import { appPaths } from '../default-routes/app-paths.library';
import { RegistrationService } from '../login/registration/registration.service';

@Injectable({
    providedIn: 'root',
})
export class VerifyEmailService {
    validationKey: string;
    userId: number; // AuthUserId
    badKeyError = `Oops something went wrong!
    - Probably the key has been used or expired.
    - Or you did something you weren't supposed to do.
    - Your best bet is to just generate a new email.`;

    private keyRegExp = /validationKey=(.+)&userId=(\d+)/;

    constructor(
        private notificationsService: NotificationsService,
        private appStateService: AppStateService,
        private authService: AuthService,
        private location: Location,
        private registrationService: RegistrationService,
    ) {}

    verifyEmail(): Observable<boolean> {
        const path = this.location.path();
        const verifyEndpoint = new RegExp(appPaths.verifyEmail);
        if (verifyEndpoint.test(path)) {
                let matches = path.match(this.keyRegExp);
                const validationKey =  matches[1];
                const userId = parseInt(matches[2], 10);
                this.registrationService.verifyDonorEmail(validationKey, userId).subscribe(
                    (answer) => {
                        if (answer.LoginResult.AuthId !== userId ) { // Safety check
                            this.error();
                            return of(false);
                        } else {
                            this.authService.updateCurrentUser(answer.UserDetails);
                            this.success();
                            return of(true);
                        }
                    },
                    (error) => {
                        if (error.status === 418) {
                            this.notificationsService.error('Verification link is invalid. Please generate a new verification email.');
                        } else if (error.status === 400) {
                            if (error.error) {
                                this.error(error.error.ModelState.Service);
                            } else {
                                this.error();
                            }
                        }
                        return of(false);
                    },
                );

        } else {
            return of(false);
        }
        return of(false);
    }

    private error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error('Something went wrong, please generate a new verification email');
        } else {
            this.notificationsService.error(msg);
        }
        console.error(this.badKeyError);
        this.appStateService.setStateToNotLoggedIn();
        setTimeout(() => this.authService.logout());
    }

    private success(): void {
        this.notificationsService.success('Email has been successfully verified!');
        this.appStateService.resolveAppState();
    }

}
