<login-wrapper [backNavRouterLink]="'/login'" [backNavText]="'Login'" [loading]="loading">
    <div class="login-box">
        <img class="logo-img" src="{{ logoFull }}" alt="Logo" />
        <div class="login-box-body">
            <p class="login-box-msg">
                Provide the email associated with your account and click Submit. If this email is in our system you will receive an email containing
                the associated Username.
            </p>
            <form [formGroup]="forgotUsernameForm" (submit)="onSubmit()">
                <div class="form-group has-feedback" [class.has-error]="emailHasError()">
                    <input type="text" autofocus class="form-control" placeholder="example@domain.com" formControlName="email" />
                    <span class="fa fa-user form-control-feedback"></span>
                    <div *ngIf="showEmailRequiredError()" class="small errortext" [style.position]="'block'">Email is required</div>
                    <div *ngIf="showEmailInvalidError()" class="small errortext" [style.position]="'block'">
                        Invalid email address. Valid e-mail can contain only letters, numbers and '@'
                    </div>
                </div>
                <div class="padded block-parent">
                    <button type="button" routerLink="/login" class="btn btn-default btn-flat inline-block block-left">Back</button>
                    <button [disabled]="!forgotUsernameForm.valid" type="submit" class="btn btn-primary btn-flat inline-block block-right">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </div>
</login-wrapper>
