import { InputTypes, DynamicField, DynamicFieldTypes, LabelPositions } from '@mt-ng2/dynamic-form';
import { CommonService } from '@angular-common/services/common.service';
import { DynamicFormModuleConfig } from '@mt-ng2/dynamic-form/libraries/dynamic-form-module.config';
import { TwebPasswordComponent } from '../form-controls/tweb-password.component';
import { Router } from '@angular/router';
import { globalInjector } from '../../../global-injector';
import { TwebAuthInputComponent } from '../form-controls/tweb-auth-input.component';
import { TwebInputComponent } from '../form-controls/tweb-input.component';
import { TwebSelectComponent } from '../form-controls/tweb-select.component';
import { TwebPhoneInputComponent } from '../form-controls/tweb-phone-input.component';
import { TwebTextAreaComponent } from '../form-controls/tweb-textarea.component';
import { TwebInputNumericComponent } from '../form-controls/tweb-input-numeric.component';
import { AppStates, AppStateService } from '../services/app-state.service';

export function customFormComponentFunction(dynamicField: DynamicField): any {
    const route: Router = globalInjector.get(Router);
    const appStateService: AppStateService = globalInjector.get(AppStateService);
    const appState = appStateService.appState.getValue();
    // TODO: Revisit. I think the idea is we no longer override the form controls after we're past the application?
    if (appState !== AppStates.applicationComplete) {
        dynamicField.labelPosition.position = LabelPositions.Hidden;

        if (dynamicField.type.fieldType === DynamicFieldTypes.Input && dynamicField.type.inputType !== InputTypes.Datepicker) {
            if (route.url.includes('register')) {
                return TwebAuthInputComponent;
            } else if (dynamicField.name.toLowerCase().includes('phone')) {
                return TwebPhoneInputComponent;
            } else if (dynamicField.validators.maxlength >= 500) {
                return TwebTextAreaComponent;
            } else {
                return TwebInputComponent;
            }
        }
        if (dynamicField.type.fieldType === DynamicFieldTypes.Numeric) {
            return TwebInputNumericComponent;
        }
        if (dynamicField.type.fieldType === DynamicFieldTypes.Password) {
            return TwebPasswordComponent;
        }
        if (
            dynamicField.type.fieldType === DynamicFieldTypes.Select &&
            dynamicField.type.inputType !== InputTypes.Datepicker &&
            dynamicField?.options?.length > 2
        ) {
            return TwebSelectComponent;
        }
    }
}

export const dynamicFormModuleConfig: DynamicFormModuleConfig = {
    commonService: CommonService,
    customFormComponentFunction: customFormComponentFunction,
};
