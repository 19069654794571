<login-wrapper [backNavText]="'Main Website'" [backNavHref]="'https://theworldeggandspermbank.com'" [loading]="loading">
    <h2 class="auth-layout__h2">Not all sperm banks are created equal.</h2>
    <br />
    <form autocomplete="off" *ngIf="loginForm" [formGroup]="loginForm" (submit)="onLogin()">
        <div class="input-scaffold">
            <label class="input-label">
                <span class="input-scaffold__label">Username</span>
                <input formControlName="username" class="input-scaffold__input input-rounded" type="text" placeholder="Username" name="login" />
            </label>
        </div>
        <div class="input-scaffold">
            <label class="input-label">
                <span class="input-scaffold__label">Password</span>
                <input
                    formControlName="password"
                    class="input-scaffold__input input-rounded"
                    type="password"
                    placeholder="Password"
                    name="password"
                />
            </label>
        </div>

        <div class="margin-top__l">
            <div class="toggle-scaffold">
                <label class="toggle">
                    <input class="toggle-scaffold__input" type="checkbox" formControlName="rememberMe" value="yes" checked="yes" />
                    <span class="toggle-scaffold__label">Remember me</span>
                </label>
            </div>
        </div>
        <br />
        <h3 class="auth-layout__h3">Don't have an account yet? <a [routerLink]="['/register']">Register here!</a></h3>
        <button type="submit" mt-doubleClickDisabled [(doubleClickIsDisabled)]="doubleClickDisabled" class="margin-bottom__xl button button--primary">
            Log In
        </button>

        <div class="auth-layout__forgot-password">
            <a class="forgot-password__link" [routerLink]="['/forgotpassword']"> Forgot Password </a>
        </div>
        <br />
        <div class="auth-layout__forgot-username">
            <a class="forgot-username__link" [routerLink]="['/forgotusername']"> Forgot Username </a>
        </div>
    </form>
</login-wrapper>
