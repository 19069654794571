import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminAccessComponent } from '@mt-ng2/login-module';

import { appPaths } from './donor-portal/default-routes/app-paths.library';
import { AuthGuard } from '@mt-ng2/auth-module';
import { LoginOverrideComponent } from 'donor-portal/login/login/login-override.component';
import { DonorForgotPasswordComponent } from 'donor-portal/login/donor-password/forgot-password.component';
import { DonorResetPasswordComponent } from 'donor-portal/login/donor-password/reset-password.component';
import { ForgotUsernameComponent } from 'donor-portal/login/forgot-username/forgot-username.component';
import { RegistrationComponent } from 'donor-portal/login/registration/registration.component';
import { MessagingComponent } from '@common/components/messaging.component';
import { ScheduleCalendlyComponent } from 'donor-portal/schedule-calendly/schedule-calendly.component';
import { DonorAccountGuard } from 'donor-portal/donor-account/account.guard';
import { RouteGuard } from './donor-portal/default-routes/route-gaurd';

const appRoutes: Routes = [
    { path: appPaths.login, component: LoginOverrideComponent },
    { path: appPaths.register, component: RegistrationComponent },
    { path: appPaths.forgotPassword, component: DonorForgotPasswordComponent },
    { path: appPaths.resetPassword, component: DonorResetPasswordComponent },
    { path: appPaths.forgotUsername, component: ForgotUsernameComponent },
    { path: appPaths.adminAccess, component: AdminAccessComponent },
    { canActivate: [AuthGuard, RouteGuard], path: appPaths.message, component: MessagingComponent },
    { canActivate: [AuthGuard, RouteGuard], path: appPaths.schedule, component: ScheduleCalendlyComponent },
    {
        canActivate: [AuthGuard, RouteGuard],
        loadChildren: () => import('./donor-portal/application-part-1/application-part-one.module').then((m) => m.ApplicationPartOneModule),
        path: appPaths.applicationPartOne,
    },
    {
        canActivate: [AuthGuard, RouteGuard],
        loadChildren: () => import('./donor-portal/application-part-2/application-part-two.module').then((m) => m.ApplicationPartTwoModule),
        path: appPaths.applicationPartTwo,
    },
    {
        canActivate: [DonorAccountGuard, RouteGuard],
        loadChildren: () => import('./donor-portal/donor-account/donor.module').then((m) => m.DonorModule),
        path: appPaths.account,
    },
    {
        path: 'verifyemail',
        pathMatch: 'full',
        redirectTo: appPaths.applicationPartOne,
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: appPaths.applicationPartOne,
    },
];
@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(appRoutes, { onSameUrlNavigation: 'reload' })],
    providers: [DonorAccountGuard],
})
export class AppRoutingModule {}
