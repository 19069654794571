import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { EnvironmentService } from '@mt-ng2/environment-module';
import { AuthService, ILoginResponse, IUserDetails } from '@mt-ng2/auth-module';

import { IDonorUser } from '@model/interfaces/donor-user';
import { ICreateDonorPayload } from '@model/interfaces/custom/create-donor-payload';

@Injectable({
    providedIn: 'root',
})
export class RegistrationService {
    private emptyUser: IDonorUser = {
        AuthUserId: 0,
        DateCreated: new Date(),
        Email: null,
        FirstName: null,
        Id: 0,
        LastName: null,
        Version: null,
    };

    constructor(public http: HttpClient, private environmentService: EnvironmentService, private authService: AuthService) {}

    getEmptyUser(): IDonorUser {
        return { ...this.emptyUser };
    }

    registerDonor(data: ICreateDonorPayload): Observable<number> {
        return this.http.post<number>(`/register`, data);
    }

    triggerNewUserActions(userId: number): Observable<void> {
        return this.http.get<void>(`/users/new-user/${userId}`);
    }

    sendVerifyEmail(userId: number): Observable<void> {
        return this.http.get<void>(`/users/send-verify-email/${userId}`);
    }

    verifyDonorEmail(validationKey: string, userId: number): Observable<ILoginResponse> {
        const data: any = {
            AuthClientID: this.environmentService.config.authClientId,
            AuthClientSecret: this.environmentService.config.authSecretVariable,
            UserId: userId,
            ValidationKey: validationKey,
        };
        return this.http
            .post<ILoginResponse>('/authUsers/verifyEmail', data, {
                headers: { BypassAuth: 'true' },
            })
            .pipe(tap((response) => this.authService.saveToken(response, false)));
    }

    /**
     * Grabs the latest values of login response and updates the custom options used to track donor progress
     * @param authUserId
     * @returns Observable<void>
     */
    refeshUserDetails(authUserId: number): Observable<void> {
        return this.http.get<IUserDetails>(`/authUsers/${authUserId}/refresh`).pipe(
            tap((loginResp) => {
                this.authService.updateCurrentUser({ CustomOptions: loginResp.CustomOptions });
            }),
            map((resp) => {
                return null;
            }),
        );
    }
}
