import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { AuthService } from '@mt-ng2/auth-module';

import { markAllFormFieldsAsTouched } from '@mt-ng2/common-functions';
import { PageTitlesService } from '@mt-ng2/page-titles';
import { ForgotUsernameService } from './forgot-username.service';
import { AppStateService } from '@common/services/app-state.service';

@Component({
    selector: 'app-forgot-username',
    templateUrl: './forgot-username.component.html',
})
export class ForgotUsernameComponent implements OnInit {
    forgotUsernameForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
    });
    logoFull = `../../../assets/logo-full.png`;
    loading: boolean;

    constructor(
        private authService: AuthService,
        private notificationsService: NotificationsService,
        private pageTitlesService: PageTitlesService,
        private ForgotUsernameService: ForgotUsernameService,
        private appStateService: AppStateService,
    ) {}

    ngOnInit(): void {
        // appReady determines if an authenticated connection has been made.
        // While it's waiting it shows a loading icon.  When appReady has a
        // value the loading icon is hidden.  We always want this to be true
        // when you are on the login page.  Because you aren't authed!
        if (this.authService.appReady && !this.authService.appReady.getValue()) {
            this.authService.appReady.next(true);
        }
        this.appStateService.setStateToForgotUsername();
        this.setPageTitle();
    }

    setPageTitle(): void {
        this.pageTitlesService.setPageTitle('Forgot Username');
    }

    emailHasError(): boolean {
        const control = this.forgotUsernameForm.get('email');
        return control.errors && (control.touched || control.errors.maxlength);
    }

    showEmailRequiredError(): boolean {
        const control = this.forgotUsernameForm.get('email');
        return control.touched && control.hasError('required');
    }

    showEmailInvalidError(): boolean {
        const control = this.forgotUsernameForm.get('email');
        return control.touched && control.hasError('email');
    }

    onSubmit(): void {
        if (this.forgotUsernameForm.valid) {
            this.loading = true;
            const values = this.forgotUsernameForm.value;
            this.ForgotUsernameService.requestUsernameEmail(values.email).subscribe(
                (success) => {
                    this.notificationsService.success(`Username has been sent successfully. Please check your email.`);
                    this.loading = false;
                },
                (error) => {
                    this.notificationsService.error('Sending email failed. Please try again later.');
                    this.loading = false;
                },
            );
        } else {
            markAllFormFieldsAsTouched(this.forgotUsernameForm);
        }
    }
}
