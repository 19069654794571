import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AppStateService } from '@common/services/app-state.service';

@Injectable({ providedIn: 'root' })
export class RouteGuard implements CanActivate {
    constructor(private appStateService: AppStateService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const currentPath = this.appStateService.CurrentPath;
        if ('/' + currentPath !== state.url) {
            this.appStateService.resolveAppState();
        }
        return true;
    }
}
