import { ErrorHandler, Injector, NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieModule } from 'ngx-cookie';
import { NgxMaskModule } from 'ngx-mask';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { ToastrModule } from 'ngx-toastr';

import { AuthModule } from '@mt-ng2/auth-module';
import { EnvironmentModule } from '@mt-ng2/environment-module';
import { BreckErrorHandler } from '@mt-ng2/errors-module';
import { MtLoginModule } from '@mt-ng2/login-module';
import { LoginModuleConfigToken } from '@mt-ng2/login-module-config';
import { NotificationsModule } from '@mt-ng2/notifications-module';
import { DynamicFormModuleConfigToken } from '@mt-ng2/dynamic-form-config';

import { AppRoutingModule } from './app-routing.module';
import { DonorPortalSharedModule } from '@common/shared.module';
import { AppComponent } from './app.component';
import { environment } from '@environment';
import { LoginConfigOverride } from '@common/configs/login.config';
import { DonorLoginModule } from 'donor-portal/login/donor-login.module';
import { VerifyEmailModule } from 'donor-portal/verify-email/verify-email.module';
import { AppNavModule } from 'donor-portal/nav/app-nav.module';
import { NotFoundComponent } from 'donor-portal/default-routes/not-found/not-found.component';
import { ForgotUsernameService } from 'donor-portal/login/forgot-username/forgot-username.service';
import { dynamicFormModuleConfig } from '@common/configs/dynamic-form-override-config';
import { setGlobalInjector } from 'global-injector';
import { ScheduleCalendlyModule } from 'donor-portal/schedule-calendly/schedule-calendly.module';

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent, NotFoundComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NgbModule,
        DonorPortalSharedModule.forRoot(),
        HttpClientModule,
        NgProgressModule.withConfig({
            color: '#ff8b56',
            spinnerPosition: 'left',
            thick: false,
        }),
        NgProgressHttpModule,
        CookieModule.forRoot(),
        EnvironmentModule.forRoot(environment),
        NotificationsModule,
        VerifyEmailModule,
        ScheduleCalendlyModule,
        DonorLoginModule.forRoot(),
        AuthModule.forRoot(),
        AppRoutingModule,
        NgxMaskModule.forRoot(),
        AppNavModule.forRoot(),
        MtLoginModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-left',
        }),
    ],
    providers: [
        { provide: ErrorHandler, useClass: BreckErrorHandler },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: LoginModuleConfigToken, useValue: LoginConfigOverride },
        { provide: DynamicFormModuleConfigToken, useValue: dynamicFormModuleConfig },
        ForgotUsernameService,
    ],
})
export class AppModule {
    constructor(injector: Injector) {
        setGlobalInjector(injector);
    }
}
